@charset "UTF-8";
@import "../../../../../../node_modules/craftcms-sass/mixins";

@mixin spinner {
  width: 24px;
  background: url(../images/spinner.gif) no-repeat 50% 50%;
}

@mixin checkered-bg($size) {
  // h/t https://gist.github.com/dfrankland/f6fed3e3ccc42e3de482b324126f9542
  $halfSize: $size / 2;
  background-image:
          linear-gradient(45deg, $grey100 25%, transparent 25%),
          linear-gradient(135deg, $grey100 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, $grey100 75%),
          linear-gradient(135deg, transparent 75%, $grey100 75%);
  background-size: $size $size;
  background-position: 0 0, $halfSize 0, $halfSize -#{$halfSize}, 0 $halfSize;
}

@mixin striped-bg($size, $color: $grey100) {
  // h/t https://css-tricks.com/stripes-css/
  $halfSize: $size / 2;
  body.ltr & {
    background: repeating-linear-gradient(135deg, $white, $white $halfSize, $color $halfSize, $color $size);
  }
  body.rtl & {
    background: repeating-linear-gradient(45deg, $white, $white $halfSize, $color $halfSize, $color $size);
  }
}

@font-face {
  font-family: 'Craft';
  src: url('../fonts/Craft.woff') format('woff'),
  url('../fonts/Craft.ttf') format('truetype'),
  url('../fonts/Craft.svg#Craft') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ----------------------------------------
/*  Basic stuff
/* ----------------------------------------*/

body, html {
  background-color: $grey100;
}

html.noscroll,
html.noscroll body {
  overflow: hidden;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-size: 14px;
  line-height: 20px;
  color: $textColor;
  -webkit-font-smoothing: subpixel-antialiased;
}

body.rtl {
  direction: rtl;
}

body, input, select, textarea {
  @include sans-serif-font;
}

.first, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child, blockquote:first-child, hr:first-child, .pane:first-child, .grid:first-child, fieldset:first-child, .field:first-child, .toolbar:first-child, .buttons:first-child {
  margin-top: 0 !important;
}

.last, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, blockquote:last-child, .pane:last-child, .grid:last-child, fieldset:last-child, .field:last-child, .toolbar:last-child, .buttons:last-child {
  margin-bottom: 0 !important;
}

.draghelper {
  box-sizing: border-box;
}

.text,
table.editable textarea {
  body.rtl .ltr & {
    text-align: left !important;
    direction: ltr !important;
  }

  body.ltr .rtl & {
    text-align: right !important;
    direction: rtl !important;
  }
}

textarea.nicetext {
  resize: none;
}

/* icons */
.icon:before,
.menu ul.padded li a.sel:before,
.menu .flex.padded.sel:before,
.texticon:before,
.element:before,
#help:before,
.secure:before,
.insecure:before,
.go:after,
.required:after,
#preview-btn:before,
#share-btn:before,
[data-icon]:before,
[data-icon-after]:after {
  @include icon;
}

.badge-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 1px solid $yellow500;
  border-radius: $smallBorderRadius;
  color: $yellow500;
  font-size: 9px;
}

.secure:before,
.insecure:before {
  margin-top: -3px;
  font-size: 14px;
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon-after]:after {
  content: attr(data-icon-after);
}

body.rtl [data-icon=list]:before,
body.rtl [data-icon-after=list]:after {
  content: 'listrtl';
}

body.rtl [data-icon=structure]:before,
body.rtl [data-icon-after=structure]:after {
  content: 'structurertl';
}

.icon.secure:before {
  content: 'secure';
}

.icon.insecure:before {
  content: 'insecure';
}

.icon.add:before {
  content: 'plus';
}

.icon.edit:before {
  content: 'edit';
}

.icon.settings:before {
  content: 'settings';
}

.icon.search:before {
  content: 'search';
}

.icon.expand:before {
  content: 'expand';
}

.icon.collapse:before {
  content: 'collapse';
}

.help:before {
  content: 'help';
  color: $pink400;
}

#preview-btn,
#share-btn {
  &:before {
    @include margin-right(7px);
  }
}

#preview-btn:before {
  margin-top: -2px;
  content: 'view';
}

#share-btn:before {
  body.ltr & {
    content: 'share';
  }
  body.rtl & {
    content: 'shareleft';
  }
}

/* headings */
h1 {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

h2 {
  margin: 14px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

h3 {
  margin: 14px 0;
  font-weight: bold;
  line-height: 1.2;
}

h4 {
  margin: 14px 0;
  font-weight: bold;
  line-height: 1.2;
  color: $mediumTextColor;
}

h5 {
  margin: 14px 0 3px;
  line-height: 1.2;
  color: $mediumTextColor;
}

h6 {
  @include h6-styles;
}

h1[data-icon]:before {
  @include margin(-8px, 10px, 0, 0)
}

h2[data-icon]:before {
  @include margin(-4px, 6px, 0, 0);
  font-size: 19px;
}

/* horizontal rule */
hr {
  margin: 24px 0;
  border: none;
  border-top: 1px solid $hairlineColor;
  height: 0;
  color: transparent;
}

.pane hr {
  margin: 24px -24px;
}

/* paragraphs */
p {
  margin: 1em 0;
}

h5 + p {
  margin-top: 0;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

.indent {
  @include margin-left(14px);
}

/* lists */
.bullets {
  @include padding-left(40px);
  list-style-type: square;
}

ol {
  @include padding-left(40px);
  list-style-type: decimal;
}

/* code */
code,
.code,
.code input,
.code textarea {
  @include fixed-width-font;

  &.smalltext {
    font-size: 0.8em !important;
  }
}

/* links */
a {
  color: $linkColor;
  cursor: pointer;

  body.underline-links & {
    text-decoration: underline;
  }
}

a:hover {
  text-decoration: underline;
}

a.sel,
li.sel a {
  cursor: default !important;
  text-decoration: none;
}

.go:after {
  font-size: 11px;
  margin-top: -1px;
  @include padding-left(4px);
  color: $lightTextColor;
  body.ltr & {
    content: 'circlerarr';
  }
  body.rtl & {
    content: 'circlelarr';
  }
}

.go:hover:after {
  color: $linkColor;
}

/* revision button */
#context-btngroup {
  @include margin-right(7px);
}
#context-btn.disabled {
  opacity: 1;
  color: $mediumDarkTextColor;
  background-color: transparentize($grey200, 0.5) !important;
}

/* status icons */
.checkmark-icon,
.alert-icon {
  padding: 5px;
  margin-bottom: 0 !important;
  line-height: 10px;
  border-radius: 20px;
  cursor: pointer;

  &:before {
    @include icon;
  }
}

.checkmark-icon {
  p & {
    display: inline-block;
  }

  background-color: $grey200;

  &:before {
    content: 'check';
    color: $successColor;
  }
}

.alert-icon {
  background-color: $grey200;

  &:before {
    content: 'alert';
    color: $errorColor;
  }
}

/* toggles */
button.toggle {
  appearance: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
}

.toggle:before,
a.fieldtoggle:before {
  @include angle(right);
  transition: transform linear 100ms;
}

.toggle.expanded:before,
a.fieldtoggle.expanded:before,
.sidebar nav li.expanded > .toggle:before,
.structure li:not(.collapsed) > .row > .toggle:before {
  transform: rotate(45deg) !important;
}

a.fieldtoggle {
  display: block;
  position: relative;
  margin: 14px 0;
  @include padding-left(12px);
  color: $textColor;
  text-decoration: none;
}

a.fieldtoggle:before {
  display: block;
  position: absolute;
  top: 7px;
  @include left(-1px);
}

/* emphasis */
em, i {
  font-style: italic;
}

strong, b, i em {
  font-weight: bold;
}

/* readable blocks */
.readable {
  @include readable;
}

/* text styles */
.leftalign {
  @include alignleft;
}

.topalign {
  vertical-align: top;
}

.rightalign {
  @include alignright;
}

.centeralign {
  text-align: center !important;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.light {
  color: $mediumTextColor !important;
  font-weight: normal;
}

.extralight {
  color: $lightTextColor !important;
}

.smalltext {
  font-size: 12px;
  line-height: 1.2;
}

.largetext {
  font-size: 16px;
  line-height: 1.2;
}

.zilch {
  padding: 100px 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: $lightTextColor;
}

input.checkbox + label.smalltext {
  padding-top: 2px;
}

.required:after {
  content: 'asterisk';
  @include margin(-2px, 0, 0, 5px);
  font-size: 7px;
  color: $errorColor;
}

.scrollpane {
  overflow: auto;
}

.left {
  @include floatleft;
}

.right {
  @include floatright;
}

th,
td {
  @include alignleft;
  vertical-align: middle;
}

body.ltr table[dir='rtl'] {
  th,
  td {
    text-align: right;
  }
}
body.rtl table[dir='ltr'] {
  th,
  td {
    text-align: left;
  }
}

th.right,
td.right {
  float: none;
  @include alignright;
}

.no-outline {
  outline: none;
}

.clear {
  display: block;
  clear: both;
  height: 0;
}

.fullwidth {
  width: 100%;
}

.token {
  @include token-styles;
}

.token[data-name='*'] {
  position: relative;
  width: 10px;
}

.token[data-name='*'] span {
  opacity: 0;
}

.token[data-name='*']:before {
  @include icon;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 9px;
  line-height: 17px;
  content: 'asterisk';
  text-indent: 0;
}

.token:focus {
  box-shadow: 0 0 0 1px transparentize($grey500, 0.75);;
  background-color: $white;
  outline: none;
}

// Override .token for Prism
.highlight {
  .token {
    display: inline;
    border: none;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    text-shadow: none;
    background: transparent;
    box-shadow: none;
  }
}

.pane.highlight {
  pre[class*="language-"] {
    overflow: visible;

    & > code.diff-highlight .token:not(.prefix) {
      margin: 0 -24px;
      padding: 0 24px;
    }
  }
}

.success {
  color: $successColor !important;
}

.notice {
  color: $noticeColor !important;
}

.warning {
  color: $warningColor !important;
}

.with-icon {
  &.notice,
  &.warning {
    &:before {
      @include margin(-2px, 2px, 0, 0);
      @include icon;
      width: 1em;
    }
  }

  &.notice:before {
    content: 'lightbulb';
  }

  &.warning:before {
    content: 'alert';
  }
}

.error {
  color: $errorColor !important;
}

.icon.move {
  display: inline-block;
}

.icon.move:not(.disabled) {
  cursor: move;
}

.icon.move:before {
  content: 'move';
  color: $darkHairlineColor;
}

.icon.move:not(.disabled):hover:before {
  color: $linkColor;
}

.icon.delete {
  display: inline-block;
}

.icon.delete:before {
  content: 'remove';
  color: $darkHairlineColor;
}

.icon.delete:not(.disabled):hover:before {
  color: $red600;
}

.icon.delete:not(.disabled):active:before {
  color: darken($red600, 10%);
}

.hidden {
  display: none !important;
}

// Visually hide without hiding from screen readers
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.invisible {
  visibility: hidden;
}

.clearafter:after {
  @include clearafter;
}

.info {
  vertical-align: bottom;
  display: inline-block;
  width: 1em;
  height: 1.375em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &:before {
    @include icon;
    vertical-align: baseline;
    width: 100%;
    line-height: 1.375;
    color: $lightTextColor;
  }

  &:not(.warning) {
    &:before {
      content: 'info';
    }
    &:hover:before {
      color: $linkColor;
    }
  }

  &.warning {
    &:before {
      content: 'alert';
    }
    &:hover:before {
      color: $warningColor;
    }
  }
}

.info-hud {
  table {
    max-width: 280px;
    table-layout: auto;
  }

  td {
    word-wrap: break-word;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .info-hud {
    table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

/* ----------------------------------------
/*  Content
/* ----------------------------------------*/

.content {
  position: relative;
}

.content:after {
  @include clearafter;
}

/* Customize Sources */
.sidebar {
  .customize-sources {
    display: block;
    margin: 14px -24px 4px;
    @include padding(7px, 14px, 7px, 24px);
    color: $lightTextColor !important;
    transition: color linear 100ms;

    .icon {
      @include margin-right(4px);
    }

    .label {
      opacity: 0;
      transition: opacity linear 100ms;
    }

    &:hover {
      color: $mediumTextColor !important;
      text-decoration: none;

      .label {
        opacity: 1;
      }
    }
  }
}

.sidebar .customize-sources:hover {
  color: $linkColor;
}

.customize-sources-modal {
  @include padding-left(200px);
}

.customize-sources-modal > .spinner {
  position: absolute;
  top: calc(50% - 44px);
  left: calc(50% - 12px);
}

.customize-sources-modal > .cs-sidebar {
  position: absolute;
  top: 0;
  @include left(0);
  margin: 0;
  padding: 10px 0;
  border: none;
  width: 200px;
  height: calc(100% - 62px);
  box-sizing: border-box;
  background-color: $grey050;
  overflow: auto;
  box-shadow: inset -1px 0 0 $hairlineColor;
}

.customize-sources-modal > .cs-sidebar > .btn {
  @include margin(10px, 0, 0, 14px);
}

.customize-sources-item {
  position: relative;
  margin-top: -1px;
  @include margin-right(1px);
  @include padding(10px, 14px, 10px, 40px);
  background-color: $grey100;
  border: solid $hairlineColor;
  border-width: 1px 0;
  user-select: none;
  cursor: default;
}

.customize-sources-item + .customize-sources-item:not(.heading) {
  border-top: 1px solid $hairlineColor;
}

.customize-sources-item.sel {
  background-color: $grey200;
  z-index: 1;
}

.customize-sources-item .move {
  display: block;
  position: absolute;
  @include left(7px);
  top: 11px;
  width: 24px;
  text-align: center;
}

.customize-sources-item + .customize-sources-item.heading {
  margin-top: 10px;
}

.customize-sources-item.heading .label {
  text-transform: uppercase;
  color: $lightTextColor;
  font-size: 12px;
  font-weight: bold;
}

.customize-sources-modal > .source-settings {
  position: relative;
  height: calc(100% - 62px);
  box-sizing: border-box;
  padding: 24px;
  overflow: auto;
}

.customize-sources-table-column .move {
  @include margin-right(10px);
}

.customize-sources-modal > .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* ----------------------------------------
/*  Icon lists
/* ----------------------------------------*/

ul.icons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0 0 10px;

    a {
      display: block;
      position: relative;
      padding: 60px 5px 10px;
      width: 110px;
      text-align: center;
      color: $textColor;
      border-radius: 4px;
      border: 1px solid $white;

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 40px;
        line-height: 60px;
      }

      .icon {
        img,
        svg {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 12px;
          left: calc(50% - 20px);
        }

        &.icon-mask svg {
          @include svg-mask($textColor);
        }
      }

      &:hover {
        text-decoration: none;
        background-color: $grey050;
        border-color: $grey100;

        .icon.icon-mask svg {
          @include svg-mask($linkColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  ul.icons li a {
    width: 96px;
  }
}

@media only screen and (max-width: 320px) {
  ul.icons li a {
    width: 75px;
  }
}

/* ----------------------------------------
/*  Buttons
/* ----------------------------------------*/

.toolbar {
  position: relative;
  margin-bottom: 14px;
  min-height: 34px;

  &.flex,
  .flex {
    align-items: flex-start;
  }

  .text {
    border-radius: $largeBorderRadius !important;
    box-shadow: none !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  align-content: stretch;

  &:not(.flex-nowrap) {
    flex-wrap: wrap;

    & > * {
      margin-bottom: 7px;
    }
  }

  & > * {
    &.label {
      white-space: nowrap;
    }

    &:not(:last-child) {
      @include margin-right(7px !important);
    }

    &.spinner {
      @include margin-left(0 !important);
    }
  }
}

.flex-grow {
  flex: 1;
}

.flex-center {
  align-items: center;
}

.spacer {
  width: 14px;
}

.buttons {
  display: flex;
  position: relative;
  margin: 24px 0;

  & > .btn,
  & > .btngroup {
    &:not(.hidden) {
      & + .btn,
      & + .btngroup {
        @include margin-left(5px);
      }
    }
  }

  .hud-footer > &,
  .footer > & {
    margin: 0;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $largeBorderRadius;
  padding: 7px 14px;
  border: none;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  color: $textColor;
  font-size: inherit;
  background-color: transparentize($inputColor, 0.75);

  &.chromeless {
    background-color: transparent;
    height: auto;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &:not(.disabled) {
    &:focus,
    &.focus,
    &:hover {
      background-color: transparentize($inputColor, 0.65);
    }

    &:active,
    &.active {
      background-color: transparentize($inputColor, 0.5);

      &:focus,
      &.focus,
      .btngroup:focus & {
        background-color: transparentize($inputColor, 0.4);
      }
    }
  }

  &[type='color'] {
    padding: 6px !important;
    width: 36px;
  }

  &.active {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  &[data-icon]:not(:empty):before,
  &.icon:not(:empty):before,
  &.menubtn[data-icon]:empty:before,
  &.menubtn.icon:empty:before {
    @include margin-right(5px);
  }

  div.checkbox {
    margin-top: 2px;
  }
}

.disabled {
  opacity: 0.25;
}

.disabled,
.disabled .btn {
  cursor: default;
}

.btn,
.spinner {
  height: 34px;
}

.btn[data-icon-after]:not(:empty):after,
.menubtn:not(:empty):after,
.menubtn.icon:after {
  @include margin-left(6px);
}

.btn[data-icon]:before,
.btn[data-icon-after]:after,
.btn.icon:before {
  position: relative;
}

.btn.small[data-icon]:before,
.btn.small[data-icon-after]:after,
.btn.icon.small:before {
  font-size: 10px;
}

.btn.icon.add.loading {
  position: relative;
}

.btn.icon.add.loading:before {
  visibility: hidden;
}

.btn.icon.add.loading:after {
  position: absolute;
  content: '';
  font-size: 0;
  display: block;
  width: 24px;
  height: 100%;
  left: 5px;
  top: 0;
  background: url(../images/spinner.gif) no-repeat 0 50%;
}

.btn.icon.add.loading.submit:after {
  background-image: url(../images/spinner_submit.gif);
}

.secondary-buttons .btn.icon.add.loading.submit:after,
.btn.secondary.icon.add.loading.submit:after {
  background-image: url(../images/spinner_submit_secondary.gif);
}

/* button groups */
.btngroup {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;

  &:focus {
    outline: none;
  }

  &.fullwidth .btn {
    flex: 1;
  }

  &.disabled .btn {
    cursor: default;
  }

  .btn {
    &.active {
      cursor: default;
    }

    &:not(.dashed):not(:last-child) {
      @include margin-right(1px);
    }

    body.ltr & {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    body.rtl & {
      &:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.copytext {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;

  body.ltr & {
    .text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  body.rtl & {
    .text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.copytextbtn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid $hairlineColor;
  border-radius: $smallBorderRadius;
  transition: border-color linear 100ms;
  padding: 0 9px;
  cursor: pointer;
  outline: none;
  transition: margin-left linear 100ms, margin-right linear 100ms;

  input {
    border: 0;
    padding: 0;
    height: 32px;
    box-shadow: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $textColor;
    text-align: center;
    outline: none;
  }

  &.light input {
    color: $mediumTextColor;
  }

  span {
    opacity: 0;
    padding: 0;
    width: 13px;
    background: none;
    @include margin-left(-13px);
    transition: opacity linear 100ms, margin-left linear 100ms, margin-right linear 100ms;
  }

  &:hover,
  &:focus {
    // Prevent the button from jumping if the added width would otherwise bump it down to the next line
    @include margin-left(-16px);
    border-color: $mediumHairlineColor;

    span {
      opacity: 1;
      @include margin-left(3px);
    }
  }

  &.small {
    padding: 0 5px;

    input {
      font-size: 11px !important;
      height: 20px;
    }
  }
}

/* menu buttons */
.menubtn {
  display: inline-flex;
  align-items: center;
  user-select: none;

  &:after {
    @include angle;
    position: relative;
  }

  &.btn {
    &:after {
      top: -1px;
    }

    &:not(.disabled):not(.inactive) {
      &:active,
      &.active {
        &:after {
          border-color: $textColor;
        }
      }
    }

    &.submit {
      &:after {
        border-color: $white !important;
        opacity: 0.8;
      }

      &:not(.disabled):not(.inactive) {
        &:hover,
        &.hover,
        &:active,
        &.active {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &:not(.btn):not(.icon) {
    height: 17px;
    &:after {
      top: 1px;
      border-color: $linkColor;
    }
  }

  &:empty {
    @include padding-left(8px);
    @include padding-right(8px);
  }
}

/* spinner */
.spinner {
  display: inline-block;
  vertical-align: bottom;
  @include spinner;

  &.over-bg {
    background-image: url(../images/spinner_bg.gif);
  }

  &.big {
    width: 48px;
    height: 48px;
    background: url(../images/spinner_big.gif) no-repeat 50% 50%;

    &.over-bg {
      background-image: url(../images/spinner_big_bg.gif);
    }
  }
}

.btn + .spinner {
  @include margin-left(7px);
}

.buttons .spinner {
  display: block;
  @include floatleft;
}

.buttons .btn + .spinner,
.buttons .btngroup + .spinner {
  @include margin-left(0);
}

.buttons.right .btn + .spinner {
  @include margin-right(-24px);
}

/* small buttons */
.btngroup.small .btn,
.btn.small {
  padding: 0 7px !important;
  font-size: 12px;
  line-height: 22px;
}

.btngroup.small,
.btngroup.small input.btn,
.btn.small,
.btn.small + .spinner {
  height: 22px;
}

/* big buttons */
.btngroup.big .btn,
.btn.big {
  padding: 0 14px;
  font-size: 14px;
  line-height: 36px;
}

.btn.big[data-icon]:before,
#preview-btn:before,
#share-btn:before {
  @include margin-left(-2px);
}

.btngroup.big,
.btngroup.big input.btn,
.btn.big,
.btn.big + .spinner {
  height: 36px;
}

/* submit buttons */
.btn.submit,
.btn.secondary {
  &,
  &:before,
  &:after {
    color: $white !important;
    @include light-on-dark-text;
  }
}

.btn.submit {
  background-color: $primaryColor !important;
}

.btn.submit:not(.disabled):not(.inactive):hover,
.btn.submit:not(.disabled):not(.inactive).hover,
.btn.submit:not(.disabled):not(.inactive):focus {
  background-color: darken($primaryColor, 5%) !important;
}

.btn.submit:not(.disabled):not(.inactive):active,
.btn.submit:not(.disabled):not(.inactive).active {
  background-color: darken($primaryColor, 10%) !important;
}

.secondary-buttons .btn.submit,
.btn.secondary {
  background-color: $secondaryColor !important;
}

.secondary-buttons .btn.submit:not(.disabled):not(.inactive):hover,
.secondary-buttons .btn.submit:not(.disabled):not(.inactive).hover,
.secondary-buttons .btn.submit:not(.disabled):not(.inactive):focus,
.btn.secondary:not(.disabled):not(.inactive):hover,
.btn.secondary:not(.disabled):not(.inactive).hover,
.btn.secondary:not(.disabled):not(.inactive):focus {
  background-color: darken($secondaryColor, 5%) !important;
}

.secondary-buttons .btn.submit:not(.disabled):not(.inactive):active,
.secondary-buttons .btn.submit:not(.disabled):not(.inactive).active,
.btn.secondary:not(.disabled):not(.inactive):active,
.btn.secondary:not(.disabled):not(.inactive).active {
  background-color: darken($secondaryColor, 10%) !important;
}

div.btn.submit {
  position: relative;
  overflow: hidden;
}

div.btn.submit input {
  position: absolute;
  left: 100%
}

/* dashed buttons */
.btn.dashed {
  border: 1px dashed $mediumHairlineColor;
  background-color: transparent !important;

  .btngroup &:not(:last-child) {
    @include border-right(1px solid transparent);
    @include margin-right(-1px);
  }

  &:focus {
    background-color: transparentize($grey200, 0.9) !important;
  }

  &:not(.disabled) {
    &:active,
    &.active {
      background-color: transparentize($grey200, 0.75) !important;
    }
  }
}

/* color inputs */
.color-input {
  @include fixed-width-font;
}

.color {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  padding: 0;

  &:not(.static) {
    cursor: pointer;
  }

  &:not(.small) {
    @include checkered-bg(17px);
  }

  &.small {
    width: 16px;
    height: 16px;
    @include checkered-bg(8px);
  }

  .color-preview {
    position: absolute;
    top: 0;
    @include left(0);
    width: 100%;
    height: 100%;
    border-radius: 17px;
    box-shadow: inset 0 0 0 1px transparentize($black, 0.85);

    & > .color-preview-input {
      position: absolute;
      @include left(0);
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      opacity: 0;
    }
  }
}

.colorhex {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  color: $mediumTextColor;
}

/* lightswitch */
.lightswitch-outer-container {
  display: flex;

  .lightswitch-inner-container {
    border: 1px solid $hairlineColor;
    border-radius: $smallBorderRadius;
    display: flex;
    align-items: center;

    span {
      padding: 7px 0;
      color: $mediumTextColor;
      cursor: default;

      &[data-toggle=on] {
        @include padding-right(10px);
        @include margin-left(7px);
      }

      &[data-toggle=off] {
        @include padding-left(10px);
        @include margin-right(7px);
      }
    }
  }
}

.lightswitch {
  position: relative;
  border: none !important;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  background-image: linear-gradient(to right, $grey300, $grey300);
  transition: background-image linear 100ms;

  &.on {
    background-image: linear-gradient(to right, $successColor, $successColor);
  }

  &.indeterminate {
    background-image: linear-gradient(to right, $successColor, $grey300);
  }

  .lightswitch-container {
    position: relative;
    height: 100%;

    .handle {
      position: absolute;
      top: 1px;
      background-color: $white;
    }
  }

  &:not(.small) {
    border-radius: 11px;
    width: 34px;
    height: 22px;

    .lightswitch-container {
      @include margin-left(-12px);
      width: 46px;

      .handle {
        border-radius: 10px;
        width: 20px;
        height: 20px;
        left: calc(50% - 10px);
      }
    }
  }

  &.small {
    border-radius: 9px;
    width: 28px;
    height: 18px;

    .lightswitch-container {
      @include margin-left(-10px);
      width: 38px;

      .handle {
        border-radius: 8px;
        width: 16px;
        height: 16px;
        left: calc(50% - 8px);
      }
    }
  }

  table & {
    display: inline-block;
    margin-bottom: -5px;
  }

  &:focus {
    outline: none;

    .lightswitch-container {
      .handle {
        background-color: $lightSelColor;
      }
    }
  }

  &.on {
    .lightswitch-container {
      @include margin-left(0);
    }
  }

  &.indeterminate {
    &:not(.small) {
      .lightswitch-container {
        @include margin-left(0.5 * -12px);
      }
    }
    &.small {
      .lightswitch-container {
        @include margin-left(0.5 * -10px);
      }
    }
  }
}

/* pagination */
.pagination {
  table.data + & {
    margin-top: 24px;
  }

  .page-link {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $mediumBorderRadius;

    &:after {
      position: relative;
      transition: border-color linear 100ms;
    }

    &.prev-page:after {
      @include angle(left, $lightTextColor);
      @include right(-1px);
    }

    &.next-page:after {
      @include angle(right, $lightTextColor);
      @include left(-1px);
    }

    &:not(.disabled) {
      transition: box-shadow linear 100ms;
      box-shadow: inset 0 0 0 1px $hairlineColor;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 1px $linkColor;

        &:after {
          border-color: $linkColor;
        }
      }
    }

    &.disabled {
      opacity: 1;
      &:after {
        border-color: $hairlineColor;
      }
    }
  }
}

/* action buttons */
.actions {
  @include floatright;
}

.actions > li {
  @include floatleft;
}

.actions > li + li {
  @include margin-left(10px);
}

h1 + .actions {
  margin-top: -100px;
}

h2 + .actions {
  margin-top: -54px;
}

/* ----------------------------------------
/*  Tables
/* ----------------------------------------*/

.tablepane {
  margin: -24px -24px -12px;
  overflow-x: auto;

  table {
    th,
    td {
      &:first-child {
        @include padding-left(24px);
      }
      &:last-child {
        @include padding-right(24px);
      }
    }

    thead {
      th,
      td {
        @include border-bottom-left-radius(0);
        @include border-bottom-right-radius(0);
      }
    }

    thead,
    tbody,
    tfoot {
      &:first-child tr:first-child {
        th,
        td {
          &:first-child {
            @include border-top-left-radius($largeBorderRadius);
          }
          &:last-child {
            @include border-top-right-radius($largeBorderRadius);
          }
        }
      }
      &:last-child tr:last-child {
        th,
        td {
          &:first-child {
            @include border-bottom-left-radius($largeBorderRadius);
          }
          &:last-child {
            @include border-bottom-right-radius($largeBorderRadius);
          }
        }
      }
    }
  }
}

table {
  &.fixed-layout {
    table-layout: fixed;
  }

  th.thin,
  td.thin {
    width: 0.01% !important;
    white-space: nowrap;
  }

  thead {
    th {
      font-weight: bold;
      @include alignleft;
      vertical-align: top;
    }
  }

  // plain tables
  &:not(.data) {
    th,
    td {
      padding-top: 7px;
      padding-bottom: 7px;

      &:not(:first-child) {
        padding-left: 12px;
      }
      &:not(:last-child) {
        padding-right: 12px;
      }
    }
  }

  // data tables
  &.data {
    th,
    td {
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;

      &.checkbox-cell {
        width: $checkboxSize !important;
        min-width: $checkboxSize;
        box-sizing: content-box;
        position: relative;

        input.checkbox + label,
        div.checkbox {
          position: absolute;
          top: calc(50% - 8px);
        }
      }
    }

    th {
      font-weight: bold;
    }

    thead {
      th,
      td {
        padding-top: 14px;
        padding-bottom: 14px;
        width: auto;
        background-color: $grey050;
        cursor: default;

        &:first-child {
          @include border-top-left-radius($largeBorderRadius);
          @include border-bottom-left-radius($largeBorderRadius);
        }

        &:last-child {
          @include border-top-right-radius($largeBorderRadius);
          @include border-bottom-right-radius($largeBorderRadius);
        }
      }

      th {
        white-space: nowrap;

        &.orderable:not(.ordered):hover {
          background-color: $grey100;
        }

        &.ordered {
          background-color: $lightSelColor;
        }

        &.ordered {
          position: relative;
          @include padding-right(26px);

          &:first-child {
            @include padding-left(10px);
          }

          &:not(.loading) {
            &:after {
              @include angle(up);
              position: absolute;
              @include right(10px);
              top: calc(50% - 3px);
            }

            &.desc:after {
              transform: rotate(45deg);
            }
          }

          &.loading {
            background-image: url(../images/spinner_tableheader.gif);
            background-repeat: no-repeat;
            body.ltr & {
              background-position: 100% 16px;
            }
            body.rtl & {
              background-position: 0 16px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        outline: none;

        &:not(.disabled) {
          &:hover {
            th,
            td {
              background-color: $grey050;
            }
          }

          &.sel {
            th,
            td {
              background-color: $lightSelColor;
            }
          }
        }
      }

      th,
      td {
        padding-top: 7px;
        padding-bottom: 7px;
        background-clip: padding-box;
      }

      td {
        &.timestamp {
          @include alignright;
          vertical-align: bottom;
          white-space: nowrap;
          color: $lightTextColor;
        }
      }
    }

    thead + tbody tr,
    tr + tr {
      th,
      td {
        border-top: 1px solid transparent;
      }
    }
  }

  // collapsable data tables for small screens
  // based on Aaron Gustafson's technique: http://blog.easy-designs.net/archives/2013/02/02/responsive-tables/
  &.collapsed {
    width: auto;

    &,
    tbody,
    tbody tr,
    tbody th,
    tbody td {
      display: block;
      border: none;
      padding: 0;
      @include alignleft;
      width: auto !important;
      white-space: normal;
    }

    thead {
      display: none;
    }

    tbody {
      tr {
        padding: 6px 0;
        border-bottom: 1px dotted $hairlineColor;

        &:after {
          @include clearafter;
        }
      }

      th,
      td {
        padding: 2px 0 !important;
      }

      td {
        &:empty {
          display: none;
        }
      }

      [data-title] {
        @include margin-right(0);

        &:before {
          margin-right: 5px;
          content: attr(data-title) ":";
          font-weight: bold;
        }

        form {
          display: inline-block;
        }
      }
    }
  }
}

.datatablesorthelper,
.editabletablesorthelper,
.thumbviewhelper {
  background-color: $white;
  @include shadow;
}

.datatablesorthelper,
.datatablesorthelper .element,
.datatablesorthelper a {
  cursor: move !important;
}

.datatablesorthelper tr:first-child th,
.datatablesorthelper tr:first-child td {
  border-top: none !important;
}

.datatablesorthelper tr:last-child th,
.datatablesorthelper tr:last-child td {
  border-bottom: none !important;
}

/* elements */
$smallThumbSize: 34px;
$largeThumbSize: 120px;
$statusSize: 10px;
$baseElementSidePadding: 7px;
$elementInnerSpacing: 5px;

.element {
  position: relative;
  cursor: default;
  outline: none !important;
  user-select: none;
  font-weight: normal;

  &:focus,
  li:focus & {
    background-color: $grey050;
  }

  &.sel,
  li.sel & {
    background-color: $lightSelColor !important;
    border-radius: $smallBorderRadius;
    cursor: default;

    &:focus {
      background-color: darken($lightSelColor, 5%) !important;
    }

    &.loading:after {
      background-image: url(../images/spinner_element.gif);
    }

    &.hasthumb {
      .elementthumb {
        img {
          box-shadow: 0 0 0 1px transparentize($darkSelColor, 0.9), 0 6px 4px -4px transparentize($darkSelColor, 0.8)
        }
      }
    }
  }

  &.hasthumb {
    .elementthumb {
      position: absolute;
      display: flex;
      justify-content: center;
      -ms-flex-pack: center;
      align-items: center;
      -ms-flex-align: center;

      img {
        display: block;
        flex-shrink: 0;
        pointer-events: none;
        border-radius: $smallBorderRadius;
        max-width: 100%;
        max-height: 100%;
      }

      &.rounded img {
        border-radius: 50%;
      }
    }
  }

  .label {
    display: inline-block;

    .draft-label {
      @include margin-left(7px);
      user-select: none;
      border: 1px solid $mediumTextColor;
      border-radius: 3px;
      display: inline-block;
      height: 16px;

      padding: 0 5px 0 4px;
      font-size: 11px;
      line-height: 16px;
      font-weight: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $mediumDarkTextColor;
    }
  }

  &.small,
  &.large:not(.hasthumb) {
    display: inline-block;
    padding: $baseElementSidePadding;
    box-sizing: border-box;

    &.hasstatus {
      @include padding-left($baseElementSidePadding + $statusSize + $elementInnerSpacing);

      .status {
        position: absolute;
        @include left($baseElementSidePadding);
        top: calc(50% - 5px);
      }
    }

    &.hasthumb {
      @include padding-left($smallThumbSize + $elementInnerSpacing);

      .elementthumb {
        top: calc(50% - 17px);
        @include left(0);
        width: $smallThumbSize;
        height: $smallThumbSize;

        &.checkered img {
          @include checkered-bg(8px);
        }
      }

      &.hasstatus {
        @include padding-left($smallThumbSize + $elementInnerSpacing * 2 + $statusSize);

        .status {
          @include left($smallThumbSize + $elementInnerSpacing);
        }
      }
    }
  }

  &.large.hasthumb {
    display: block;
    padding: #{$baseElementSidePadding + $largeThumbSize + $elementInnerSpacing} $baseElementSidePadding $baseElementSidePadding;
    width: #{120px + $baseElementSidePadding + $baseElementSidePadding};
    box-sizing: border-box;

    &.hasstatus {
      @include padding-left($baseElementSidePadding + $statusSize + $elementInnerSpacing);

      .status {
        position: absolute;
        @include left($baseElementSidePadding);
        top: #{$baseElementSidePadding + $largeThumbSize + $elementInnerSpacing + 6};
      }
    }

    .elementthumb {
      top: $baseElementSidePadding;
      @include left($baseElementSidePadding);
      width: $largeThumbSize;
      height: $largeThumbSize;

      &.checkered img {
        @include checkered-bg(15px);
      }
    }

    .label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
    }
  }

  &.removable {
    .label {
      @include padding-right(20px);
    }

    .delete:before {
      color: $darkHairlineColor;
    }

    &.small,
    &.large:not(.hasthumb) {
      .delete {
        position: absolute;
        top: calc(50% - 11px);
        @include right($baseElementSidePadding);
      }
    }

    &.large.hasthumb {
      .delete {
        position: absolute;
        @include right($baseElementSidePadding);
      }
    }
  }

  &.loading {
    @include padding-right($baseElementSidePadding + 24);

    &:after {
      content: '';
      font-size: 0;
      position: absolute;
      bottom: 0;
      @include right(3px);
      @include spinner;
      height: 32px;
    }

    &.removable .delete {
      display: none;
    }
  }
}

$checkboxPadding: $checkboxSize + 4;

.elements {
  position: relative;

  &.busy {
    min-height: 200px;

    &:after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      top: 0;
      left: -24px;
      width: calc(100% + #{24 + 24}px);
      height: 100%;
      background: transparentize($white, 0.25) url(../images/spinner.gif) no-repeat 50% 50%;;
      border-radius: $largeBorderRadius;
    }
  }

  .header {
    margin: -24px -24px 24px;
    padding: 14px 24px;
    background-color: $grey050;
    box-shadow: none;

    &:after {
      content: '';
    }

    .selectallcontainer {
      cursor: default;

      &:focus {
        outline: none;
      }

      .checkbox {
        @include margin-right($elementInnerSpacing);
      }
    }
  }

  // table views
  .tableview {
    thead {
      th.selectallcontainer {
        outline: none;
      }
    }

    .move {
      display: block;
      position: absolute;
      top: calc(50% - 11px);
      @include margin-left(-14px);
      font-size: 11px;
      text-decoration: none;
    }

    .toggle {
      display: block;
      position: absolute;
      top: calc(50% - 7px);
      @include margin-left(-16px);
      padding: 4px;
    }

    .move + .toggle {
      @include margin-left(-34px);
    }
  }

  // thumbs views
  .thumbsview {
    @include margin(-$baseElementSidePadding, -$baseElementSidePadding - 1px, -$baseElementSidePadding - 1px, -$baseElementSidePadding);
    width: calc(100% + #{$baseElementSidePadding + $baseElementSidePadding + 1px});
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      position: relative;
      @include margin(0, 1px, 1px, 0);
      outline: none;

      &:hover {
        .element {
          background-color: $grey050;
        }
      }

      &.has-checkbox {
        .element.hasthumb {
          &:not(.hasstatus) {
            @include padding-left($baseElementSidePadding + $checkboxSize + $elementInnerSpacing);
          }

          &.hasstatus {
            @include padding-left($baseElementSidePadding + $checkboxSize + $elementInnerSpacing + $statusSize + $elementInnerSpacing);

            .status {
              @include left($baseElementSidePadding + $checkboxSize + $elementInnerSpacing);
            }
          }
        }

        .checkbox {
          position: absolute;
          top: $baseElementSidePadding + $largeThumbSize + $elementInnerSpacing + (20px - $checkboxSize) / 2;
          @include left($baseElementSidePadding);
        }
      }
    }
  }
}

.export-form {
  position: relative;
  .spinner {
    position: absolute;
    bottom: 0;
    @include right(-24px);
  }
}

.thumbviewhelper {
  margin: -7px;
  padding: 7px;

  .thumbsview {
    &,
    li {
      margin: 0 !important;
    }
  }
}

/* structures */
.structure {
  position: relative;
  z-index: 1;

  li {
    @include padding-left(8px);

    &.collapsed > ul {
      display: none;
    }

    .row:hover > .icon,
    &.draghelper > .row .move,
    .add.active {
      opacity: 1;
    }

    &.draghelper {
      & > .row {
        .add {
          opacity: 0;
        }

        .move:before {
          color: $linkColor;
        }
      }
    }

    &.draginsertion {
      position: relative;
      @include margin(-1px, 0, -1px, 8px);
      @include padding-left(0);
      height: 2px;
      background-color: $linkColor !important;
      @include border-left(none);
      border-radius: 1px;
    }

    .toggle {
      position: relative;
      z-index: 1;
      @include floatleft;
      @include margin(10px, -8px, 0, -12px);
      padding: 4px;
    }

    .row:after {
      @include clearafter;
    }

    .move,
    .add {
      @include margin(5px, 5px, 0, 0);
      opacity: 0;
      transition: opacity linear 100ms;
    }

    .add {
      padding: 0 5px;

      &:before {
        content: 'downangle';
        color: $darkHairlineColor;
      }

      &:not(.disabled):hover:before,
      &.active:before {
        color: $linkColor;
      }
    }

    .checkbox {
      @include floatleft;
      @include margin(7px, 0, 0, 7px);
    }
  }

  ul {
    @include margin-left(-3px);

    li {
      @include padding-left(38px);
      background-repeat: no-repeat;

      body.ltr & {
        background-image: url(../images/branch.png);
        background-position: 0 0;
      }

      body.rtl & {
        background-image: url(../images/branch_rtl.png);
        background-position: 100% 0;
      }

      &:not(:last-child):not(.last) {
        @include padding-left(37px);
        @include border-left(1px solid $hairlineColor);

        body.ltr & {
          background-position: -1px 0;
        }

        body.rtl & {
          background-position: calc(100% + 1px) 0;
        }
      }

      &.draginsertion {
        @include margin-left(38px);
      }
    }
  }

  .row {
    &.draghover {
      .element {
        z-index: 2;
        border-radius: 15px;
        box-shadow: inset 0 0 0 2px $linkColor;
      }
    }

    &.droptarget {
      border-radius: 5px;
      box-shadow: inset 0 0 0 2px $linkColor;
    }
  }
}

/* element select fields */
.elementselect {
  position: relative;
  min-height: 37px;
  margin-top: -7px;

  &:after {
    @include clearafter;
  }

  .element,
  .btn {
    @include floatleft;
    @include margin(7px, 7px, 0, 0);
  }

  .element.small,
  .flex,
  .btn {
    clear: both;
  }

  .element {
    z-index: 1;

    &.small {
      max-width: 100%;

      .label {
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .caboose {
    @include floatleft;
  }

  .flex .btn {
    float: none !important;
  }
}

/* editable tables */
table.editable {
  border-radius: $largeBorderRadius;
  border: 1px solid $grey200;

  th,
  td.action {
    color: $mediumTextColor;
    font-weight: normal;
    background-color: $grey050;
  }

  thead,
  tbody {
    tr {
      th {
        padding: 6px 10px;
      }
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid $hairlineColor;

        &.has-info {
          @include padding-right(calc(15px + 1em));
        }

        span.info {
          position: absolute;
          margin-left: 5px;
        }
      }
    }
  }

  tbody {
    tr {
      &:not(:first-child) {
        th,
        td {
          border-top: 1px solid $hairlineColor;
        }
      }

      &:last-child {
        td:first-child {
          @include border-bottom-left-radius($largeBorderRadius);

          textarea,
          input.text {
            @include border-bottom-left-radius($largeBorderRadius - 1);
          }
        }
      }

      td:not(:first-child),
      th ~ td:not(.hidden) ~ td {
        @include border-left(1px solid $hairlineColor);
      }

      th {
        // Set a dark border-left for the first <td> that follows a <th>, if there is one.
        // This is a ridiculous CSS hack since there's no operator/pseudo-class that mimics jQuery's next(selector) function.
        // If there was it could have been as simple as: th ??? td:not(.hidden) { dark left border }
        // kudos to Mark Huot for coming up with it!
        & ~ td:not(:first-child) {
          @include border-left(1px solid #dbdddf);
        }
      }

      td {
        vertical-align: top;
        text-align: center;
        background-color: $white;
        padding: 4px 10px;

        &.focus {
          box-shadow: inset 0 0 0 1px $hairlineColor;
        }

        &.textual {
          padding: 0;

          textarea {
            resize: none;
          }

          pre {
            @include alignleft;
            white-space: pre-wrap;
          }
        }

        &.lightswitch-cell {
          padding-top: 9px;
          padding-bottom: 9px;

          .lightswitch {
            display: block;
            margin: 0 auto;
          }
        }

        &.checkbox-cell {
          padding-top: 10px;
          padding-bottom: 10px;

          .checkbox-wrapper {
            display: block;
            margin: -2px auto 0;
            width: 16px;
            height: 16px;
          }
        }

        &.error {
          box-shadow: inset 0 0 0 1px $errorColor;
        }

        &.disabled {
          position: relative;
          opacity: 1;

          &:after {
            content: '';
            font-size: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($grey050, 0.25);
            user-select: none;
          }
        }

        &.action {
          padding: 4px 7px;

          & + td.action {
            @include border-left(none);
            @include padding-left(0);
          }
        }

        .flex > * {
          margin-bottom: 0;
        }
      }
    }

    textarea,
    textarea.text,
    input.text,
    pre {
      display: block;
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
      padding: 7px 10px;
      background-color: transparent;
      overflow: hidden;
      transition: none;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    .color-container {
      display: block;
      position: relative;

      & > .color,
      & > .color-input {
        margin-bottom: 0;
      }

      & > .color {
        position: absolute;
        top: 10px;
        @include left(10px);
      }

      & > .color-input {
        @include padding-left(35px);
      }
    }

    .datewrapper,
    .timewrapper {
      display: block;
      width: 100%;

      .text:placeholder-shown + div[data-icon] {
        top: 6px;
        @include left(10px);
      }
    }
  }

  &:not(.static) {
    td.textual {
      cursor: text;
    }
  }

  & + .btn.add {
    display: block;
    width: 100%;
    border: 1px dashed $mediumHairlineColor;
    background-color: transparent;
    box-shadow: none;

    &:focus {
      border-style: solid;
      border-color: $blue600;
    }
  }

  &:not(.hidden) + .btn.add {
    border-top-width: 0;
    border-radius: 0 0 $mediumBorderRadius $mediumBorderRadius;
  }
}

.border-box,
.shadow-box {
  & + .buttons {
    margin-top: 7px;
  }
}

/* ----------------------------------------
/*  Nav
/* ----------------------------------------*/

ul.tree,
.tree ul {
  @include margin-left(20px);
}

.tree li .toggle {
  @include margin(7px, 0, 0, -15px);
}

/* status icons */
.status {
  display: inline-block;
  @include margin-right(10px);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;

  body.use-shapes & {
    &.pending {
      background-color: transparent;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent $yellow700 transparent;
      border-radius: 1px;
    }

    &.off,
    &.suspended,
    &.expired {
      border-radius: 1px;
    }
  }
}

.status:not(.on):not(.live):not(.active):not(.enabled):not(.pending):not(.off):not(.suspended):not(.expired):not(.yellow):not(.orange):not(.red):not(.pink):not(.purple):not(.blue):not(.green):not(.turquoise):not(.light):not(.grey):not(.black) {
  border-color: transparentize($inputColor, 0.2);
}

.green,
.status.on,
.status.live,
.status.active,
.status.enabled {
  background-color: $teal500;
}

/* green */

.orange,
.status.pending {
  background-color: $yellow700;
}

/* orange */

.red,
.status.off,
.status.suspended,
.status.expired {
  background-color: $red600;
}

/* red */

.yellow {
  background-color: $yellow300;
}

.pink {
  background-color: $pink400;
}

.purple {
  background-color: #9B59B6;
}

.blue {
  background-color: $blue600;
}

.turquoise {
  background-color: $teal300;
}

.status.light {
  background-color: $grey100;
}

.grey {
  background-color: $grey300;
}

.black {
  background-color: $grey800;
}

.status.white,
.status.disabled {
  opacity: 1;
}

/* ----------------------------------------
/*  Progress bar
/* ----------------------------------------*/

.progressbar {
  border-radius: 6px;
  border: 2px solid $grey700;
  padding: 2px;
  position: absolute;
  left: 20%;
  width: 60%;
  z-index: 1000;
}

.progressbar-inner {
  border-radius: 2px;
  height: 4px;
  background-color: $grey700;
}

.progressbar:not(.pending) .progressbar-inner {
  width: 0;
  transition: width linear 100ms;
}

.progressbar.pending .progressbar-inner {
  @include striped-bg(17.6776695297px, $grey700); // sqrt(25^2 / 2);
  body.ltr & {
    animation-name: pendingprogress-ltr;
  }
  body.rtl & {
    animation-name: pendingprogress-rtl;
  }
  animation-timing-function: linear;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
}

@keyframes pendingprogress-ltr {
  from {
    background-position: 0;
  }
  to {
    background-position: 25px;
  }
}

@keyframes pendingprogress-rtl {
  from {
    background-position: 0;
  }
  to {
    background-position: -25px;
  }
}

.elementselect .progress-shade {
  background-color: transparentize($white, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  @include left(0);
  display: none;
}

.elementselect.uploading {
  position: relative;
}

.elementselect.uploading .progress-shade {
  display: block;
  z-index: 2;
}

// Plugin installers

.missing-component {
  padding: 7px 10px !important;
  max-width: 400px;
  background-color: $grey050 !important;

  .error {
    margin: 0;
  }

  .install-plugin {
    margin: 7px 0 -7px;
    border-top: 1px solid $hairlineColor;
    position: relative;
    @include padding(10px, 0, 10px, 40px);

    .icon {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(50% - 16px);
      @include left(0);

      img, svg {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      flex: 1;
      margin: 8px 0 !important;
    }

    .btn {
      margin: 0;
    }
  }
}

/* ----------------------------------------
/*  Panes, Modals and HUDs
/* ----------------------------------------*/

.pane {
  @include pane;
  position: relative;
  margin: 14px 0;
  padding: 24px;
  border-radius: $largeBorderRadius;
  word-wrap: break-word;
  box-sizing: border-box;

  .pane &,
  #content & {
    background-color: $grey050;
    border: 1px solid $hairlineColor;
    box-shadow: none;
  }

  &.loading {
    min-height: 200px;

    &:after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      top: 0;
      left: -24px;
      width: calc(100% + #{24 + 24}px);
      height: 100%;
      background: url(../images/spinner.gif) no-repeat 50% 50%;;
    }
  }
}

/* meta panes */
.meta {
  padding: 0 24px;
  overflow: visible;

  &,
  & > .flex-fields {
    & > .field,
    & > .data {
      display: flex;
      flex-wrap: wrap; // for error lists
      justify-content: space-between;
      align-items: center;
      margin: 0 -24px !important;
      padding: 0 24px;
      transition: padding-left linear 100ms, padding-right linear 100ms;

      &.nested {
        @include padding-left(38px);
      }

      &.add {
        background-color: darken($grey050, 2%);

        &:before {
          position: absolute;
          @include left(0);
          width: 31px;
          @include alignright;
          @include icon;
          content: 'plus';
          color: $lightTextColor;
        }

        .input {
          width: 100%;
        }
      }

      & > .heading {
        flex: 0 0 105px;
        @include margin(0, 7px, 0, 0);
        line-height: 18px;
      }

      &.lightswitch-field > .heading {
        flex: 1;
      }

      & > .input {
        .flex {
          flex-wrap: nowrap;

          & > * {
            margin-bottom: 0;
          }
        }
      }
    }

    & > .field > .heading {
      padding: 14px 0;

      & > .copytextbtn {
        display: none;
      }
    }

    & > .data > .heading {
      padding: 7px 0;
    }

    & > .field > .heading > label,
    & > .data > .heading {
      color: $mediumTextColor;
    }

    & > .field > .input,
    & > .data > .value {
      padding: 7px 0;
      width: calc(100% - 112px);
    }

    & > .field.lightswitch-field > .input {
      flex: 0;
      width: auto;
    }

    & > .field {
      &.has-errors {
        border: 1px solid $errorColor !important;

        &:first-child {
          border-top-left-radius: $largeBorderRadius;
          border-top-right-radius: $largeBorderRadius;
        }

        &:last-child {
          border-bottom-left-radius: $largeBorderRadius;
          border-bottom-right-radius: $largeBorderRadius;
        }

        & + .field {
          border-top: none !important;
        }
      }

      & > .heading {
        & > label {
          font-weight: normal;
        }
      }

      & > .input {
        &,
        & > .flex,
        & > .flex > .textwrapper,
        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          & > .text {
            display: block;
            margin: -7px 0;
            padding: 14px 0;
            border-radius: 0;
            box-shadow: none;
            background-color: transparent;
            border: none !important;
          }
        }

        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          background-color: transparent;
          .text:placeholder-shown + div[data-icon] {
            @include left(0);
          }
        }

        & > .datetimewrapper {
          & > .datewrapper {
            width: 55%;
          }

          & > .timewrapper {
            width: 45%;
          }

          & > .clear-btn {
            @include margin-right(-24px);
          }
        }

        & > .datewrapper,
        & > .timewrapper {
          display: block;
          width: 100%;
        }
      }

      & > ul.errors {
        margin: 0;
        padding: 0 0 6px;
        width: 100%;
        list-style-type: none;
      }

      & > .clear-btn {
        @include margin-right(-24px);
      }
    }
  }

  & > .field:not(:first-child),
  & > .flex-fields + .field {
    border-top: 1px solid $hairlineColor;
  }

  & > .flex-fields {
    h2,
    blockquote.note {
      margin: 0 -24px !important;
      padding: 14px 24px;
      background-color: darken($grey050, 2%);
    }

    blockquote.note {
      border-radius: 0;
      border: none;
    }

    hr {
      margin: 0 -24px;
    }
  }
}

.meta > .field > .input > .select {
  display: block;
  margin: -7px 0;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;

  &:after {
    @include right(0);
  }

  & + .spinner {
    position: absolute;
    top: calc(50% - 17px);
    @include right(-24px);
  }

  select {
    @include padding(7px, 12px, 7px, 0);
    width: 100%;
    background-color: transparent;
  }
}

.body {
  position: relative;
}

.modal,
.hud {
  z-index: 100;
  box-sizing: border-box;
}

.modal,
.hud .body {
  @include modal;
}

.header,
.hud-header,
.footer,
.hud-footer,
.body {
  &:after {
    @include clearafter;
  }
}

.header,
.hud-header,
.footer,
.hud-footer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.header,
.hud-header,
.footer,
.hud-footer {
  background-color: $grey100;
}

.header,
.hud-header {
  border-radius: $largeBorderRadius $largeBorderRadius 0 0;
  padding: 24px;
  box-shadow: inset 0 -1px 0 $hairlineColor;

  h1 {
    margin: 0;
  }
}

.footer,
.hud-footer {
  border-radius: 0 0 $largeBorderRadius $largeBorderRadius;
  padding: 14px 24px;
  box-shadow: inset 0 1px 0 $hairlineColor;

  &.flex {
    & > * {
      margin-bottom: 0;
    }
  }
}

.modal .body,
.hud .main {
  padding: 24px;
  overflow: hidden;
  box-sizing: border-box;
}

.pane,
.modal .body {
  .header {
    margin: -24px -24px 24px;
  }

  .footer {
    margin: 24px -24px -24px;
  }
}

.modal-shade,
.hud-shade {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.modal-shade {
  &:not(.dark) {
    background-color: transparentize($grey100, 0.35) !important;
  }

  &.dark {
    background-color: transparentize($grey900, 0.5) !important;
  }
}

.modal {
  position: fixed;
  width: 66%;
  height: 66%;
  min-width: 600px;
  min-height: 400px;
  overflow: hidden;

  &.fitted {
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
  }

  &.alert .body {
    @include padding-left(76px);

    &:before {
      @include icon;
      @include margin(-6px, 0, 0, -58px);
      @include floatleft;
      content: 'alert';
      font-size: 40px;
      color: $lightTextColor;
    }
  }

  &.secure .body {
    @include padding-left(76px);

    &:before {
      @include icon;
      @include margin(-14px, 0, 0, -56px);
      @include floatleft;
      content: 'secure';
      font-size: 58px;
      color: $lightTextColor;
    }
  }

  .resizehandle {
    position: absolute;
    z-index: 1;
    bottom: 0;
    @include right(0);
    width: 24px;
    height: 24px;
    background: no-repeat 50% 50%;
    cursor: nwse-resize;
    body.ltr & {
      background-image: url(../images/resizehandle.png);
    }
    body.rtl & {
      background-image: url(../images/resizehandle_rtl.png);
    }
  }
}

.hud {
  position: absolute;
  display: none;
  top: 0;

  &.has-footer .tip-bottom {
    background-image: url(../images/hudtip_bottom_gray.png);
  }

  .tip {
    position: absolute;
    z-index: 101;
    background: no-repeat 0 0;
  }

  .tip-left {
    left: -15px;
    width: 15px;
    height: 30px;
    background-image: url(../images/hudtip_left.png);
  }

  .tip-top {
    top: -15px;
    width: 30px;
    height: 15px;
    background-image: url(../images/hudtip_top.png);
  }

  .tip-right {
    right: -15px;
    width: 15px;
    height: 30px;
    background-image: url(../images/hudtip_right.png);
  }

  .tip-bottom {
    bottom: -15px;
    width: 30px;
    height: 15px;
    background-image: url(../images/hudtip_bottom.png);
  }
}

.hud .hud-header,
.hud .hud-footer {
  padding: 7px 24px;
}

.hud .body {
  overflow: hidden;

  ::-webkit-scrollbar {
    appearance: none;

    &:vertical {
      width: 11px;
    }

    &:horizontal {
      height: 11px;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: transparentize($black, 0.5);
    background-clip: content-box;
  }

  ::-webkit-scrollbar-track {
    background-color: $grey050;
  }
}

/* inline asset previews */

.preview-thumb-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 190px;
  background-color: $grey900;
  cursor: pointer;

  &.loading {
    &:after {
      content: '';
      font-size: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: transparentize($grey900, 0.2) no-repeat 50% 50% url(../images/spinner_dark.gif);
    }
  }

  #details & {
    margin-bottom: 14px;
    border-radius: $largeBorderRadius;
    overflow: hidden;
  }

  .preview-thumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    img {
      display: block;
      max-width: 100%;
      max-height: 190px;
    }
  }

  .buttons {
    opacity: 0;
    position: absolute;
    top: 10px;
    @include right(10px);
    margin: 0;
    transition: opacity linear 100ms;

    .btn {
      background-color: $grey600;
      color: $white;
      @include light-on-dark-text;

      @supports (backdrop-filter: blur(10px)) {
        & {
          background-color: transparentize($grey600, 0.6);
          backdrop-filter: blur(10px);
          transition: opacity linear 100ms, backdrop-filter linear 100ms;
        }
      }

      &:hover {
        background-color: $grey500;

        @supports (backdrop-filter: blur(10px)) {
          & {
            background-color: transparentize($grey500, 0.3);
          }
        }
      }
    }
  }

  &:hover .buttons {
    opacity: 1;
  }
}

/* element editor HUDs */

@media (min-width: 440px) {
  .hud .elementeditor {
    min-width: 380px;
  }
}

.hud .elementeditor {
  max-width: 600px;

  .hud-header {
    text-align: center;
  }

  .main {
    padding: 8px 0;

    .meta {
      .preview-thumb-container {
        margin: -8px -24px 8px;
      }

      .field {
        padding-left: 24px;
        padding-right: 24px;

        &.has-errors {
          border-radius: 0;
        }
      }
    }
  }
}

/* element selector modals */
.elementselectormodal {
  padding-bottom: 62px;
  user-select: none;

  .body {
    position: relative;
    height: 100%;

    .spinner.big {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -24px 0 0 -24px;
    }

    .content {
      height: calc(100% + 48px);

      .sidebar {
        position: absolute;
        top: 0;
        @include margin-left(-249px);
        height: 100%;
        overflow: auto;
      }

      .main {
        margin: -24px;
        padding: 24px;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        position: relative;

        .elements {
          &.busy {
            min-height: calc(100% - 48px);
          }

          .tableview table {
            .element {
              display: inline-block;
            }

            tr {
              &:focus {
                outline: none;
              }

              &.disabled {
                opacity: 1;
                color: $grey200;

                .element {
                  opacity: 0.25;
                }
              }

              th,
              td {
                cursor: default;
              }

              td:first-child {
                @include padding-left(7px);
              }
            }
          }

          .structure .row {
            margin-top: 1px;
            outline: none;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;

    .spinner {
      @include floatright;
      @include margin-right(-24px);
    }
  }
}

/* element editing HUD */
.element-hud-form {
  .buttons {
    position: relative;

    .spinner {
      position: absolute;
      top: 0;
      @include right(-24px);
    }
  }
}

/* logout warning/login/elevated session modals */
.logoutwarningmodalshade,
.loginmodalshade {
  z-index: 101;
}

#logoutwarningmodal,
#loginmodal,
#elevatedsessionmodal {
  width: 500px;
}

#logoutwarningmodal,
#loginmodal {
  z-index: 101;
}

#loginmodal,
#elevatedsessionmodal {
  .inputcontainer {
    position: relative;

    .spinner {
      position: absolute;
      top: 0;
      margin-top: 0;
      @include right(-24px);
    }
  }
}

/* delete user modal */
.deleteusermodal {
  .content-summary {
    margin: -24px -24px 24px;
    padding: 24px;
    background-color: $grey050;
  }

  .options {
    label {
      display: inline-block;
      line-height: 30px;
    }
  }

  .elementselect {
    @include margin-left(10px);
    display: inline-block;
    vertical-align: middle;
  }

  .buttons {
    .spinner {
      @include margin-right(-20px);
    }
  }
}

.dropdownsettingsmodal {
  width: auto;
  height: auto;
  min-width: 0;
  min-height: 0;
  max-width: 400px;

  .body {
    max-height: 100%;
    overflow-y: auto;
  }
}

.previewmodal {
  &.zilch {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* ----------------------------------------
/*  Menus
/* ----------------------------------------*/

.menu,
.ui-datepicker,
.ui-timepicker-list {
  @include menu-styles;
}

.ui-datepicker,
.ui-timepicker-list {
  padding: 0;
}

.menu {
  display: none;
  position: absolute;

  h6 {
    &:first-child {
      margin-top: 14px !important;
    }
  }

  ul {
    &.padded {
      li {
        a {
          @include padding-left(24px);

          &[data-icon],
          &.icon,
          &.sel {
            &:before {
              @include floatleft;
              @include margin(3px, 0, 0, -17px);
              font-size: 14px;
              color: $lightTextColor;
            }

            &.error:before {
              color: $errorColor;
            }
          }

          &.sel:not([data-icon]):before {
            content: 'check';
          }
        }
      }
    }

    li {
      a {
        @include menu-option-styles;
        font-size: 14px;

        &:not(.flex) {
          display: block !important;
        }

        &.sel {
          cursor: default;
        }

        .shortcut {
          @include floatright;
          @include margin-left(14px);
          color: $mediumTextColor;
        }
      }
    }
  }

  & > .flex {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    &.padded {
      @include margin-left(-14px);
      @include padding-left(24px);

      &.sel {
        &:before {
          position: absolute;
          top: 36px;
          @include left(7px);
          content: 'check';
          font-size: 14px;
          color: $lightTextColor;
        }
      }
    }
  }

  hr {
    margin: 5px -14px;
  }
}

.menubtn,
.menu {
  span.icon {
    display: inline-block;
    margin-top: -1px;
    width: 10px;
    @include margin-right(10px);
    text-align: center;
    font-size: 14px;
    color: $lightTextColor;
  }
}

.menu ul li a:not(.sel):not(.disabled):hover,
.menu ul li a:not(.sel):not(.disabled):hover .light,
.menu:not(:hover) ul li a:not(.disabled).hover,
.menu:not(:hover) ul li a:not(.sel):not(.disabled).hover .light {
  @include menu-option-active-styles;
}

.menu hr.padded {
  @include margin-left(10px);
}

/* tag select fields */
.tagselect {
  .elements {
    display: inline;
  }

  .element.small {
    clear: none;
  }

  .add {
    position: relative;
    z-index: 1;
    @include margin(7px, 7px, 0, 0);
    display: inline-block;
    width: 12em;

    .text {
      @include padding-right(30px);
    }

    .spinner {
      position: absolute;
      top: 0;
      @include right(5px);
    }
  }

  // todo: why are body.ltr and body.rtl needed here?
  body.ltr &,
  body.rtl & {
    &.elementselect .element {
      float: none !important;
      display: inline-block;
    }
  }
}

.tagmenu {
  ul {
    li {
      a {
        @include padding-left(26px);

        &:before {
          @include floatleft;
          @include margin(3px, 0, 0, -18px);
        }
      }
    }
  }
}

/* selectize */

/* ----------------------------------------
/*  Fields
/* ----------------------------------------*/

.shadow-box {
  border-radius: $largeBorderRadius;
  border: 1px solid $grey200;
  @include shadow;
}

table.shadow-box,
table.editable {
  thead:first-child,
  tbody:first-child {
    tr:first-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled:after {
          border-top-left-radius: $mediumBorderRadius;
        }
      }
      th:last-child,
      td:last-child {
        &,
        &.disabled:after {
          border-top-right-radius: $mediumBorderRadius;
        }
      }
    }
  }
  thead:last-child,
  tbody:last-child {
    tr:last-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled:after {
          border-bottom-left-radius: $largeBorderRadius - 1;
        }
      }
      th:last-child,
      td:last-child {
        &,
        &.disabled:after {
          border-bottom-right-radius: $largeBorderRadius - 1;
        }
      }
    }
  }
}

.text:not(.selectize-text),
.passwordwrapper,
.border-box,
.matrix-configurator > .field > .input,
.selectize-text > .selectize-control > .selectize-input,
.multiselect > select,
.selectize.multiselect .selectize-control.multi .selectize-input {
  @include input-styles;
}

.text,
.passwordwrapper,
.border-box,
.selectize-text > .selectize-control > .selectize-input {
  &.focus,
  &:focus {
    @include input-focused-styles;
  }
}

input.text,
textarea.text,
.text > input,
.text > textarea,
table.editable textarea,
.selectize-text > .selectize-control > .selectize-input {
  font-size: 14px;
  line-height: 20px;
  color: $textColor;
  min-height: 3px;
  box-sizing: border-box;
  appearance: none;
}

.selectize-text > .selectize-control > .selectize-input {
  line-height: 18px;
}

textarea.text.fullwidth {
  display: block;
}

.multitext .multitextrow {
  &:after {
    @include clearafter;
  }

  &:first-child .text {
    &:first-child {
      @include border-top-left-radius($mediumBorderRadius);
    }

    &:last-child {
      @include border-top-right-radius($mediumBorderRadius);
    }
  }

  &:last-child .text {
    &:first-child {
      @include border-bottom-left-radius($mediumBorderRadius);
    }

    &:last-child {
      @include border-bottom-right-radius($mediumBorderRadius);
    }
  }

  &:not(:first-child) .text {
    margin-top: -1px;
  }

  .text {
    border-radius: 0;
    float: left;
    box-sizing: border-box;

    &:not(:first-child) {
      @include margin-left(-1px);
    }

    &:first-child {
      &:nth-last-child(1) {
        width: 100%;
      }

      &:nth-last-child(2) {
        width: 50%;
      }

      &:nth-last-child(2) ~ .text {
        width: calc(50% + 1px);
      }
    }

    &.error {
      position: relative;
      z-index: 1;
    }
  }

  .text:focus,
  .selectize-text > .selectize-control > .selectize-input.focus {
    position: relative;
    z-index: 2;
  }
}

.chars-left {
  position: relative;
  @include floatright;
  @include margin(-27px, 7px, 0, 0);
  color: $lightTextColor;

  &.negative-chars-left {
    color: $errorColor;
  }
}

.field,
fieldset {
  position: relative;
  margin: 24px 0;

  .flex > & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.field {
  & > .status-badge {
    position: absolute;
    top: -3px;
    @include left(-19px);
    font-size: 9px;
    font-weight: bold;
    line-height: 15px;
    width: 15px;
    text-align: center;
    cursor: default;
    border-radius: $smallBorderRadius;

    &.modified {
      background-color: $blue600;
      color: $white;
      @include light-on-dark-text;
    }

    &.outdated {
      background-color: $yellow300;
    }

    &.conflicted {
      background-color: $red600;
      color: $white;
      @include light-on-dark-text;
    }
  }

  & > .heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-top: -5px;
    margin-bottom: 5px;

    & > label,
    & > legend {
      font-weight: bold;
      color: $mediumDarkTextColor;

      code {
        font-size: 1em !important;
      }

      .info {
        @include margin-left(5px);
      }
    }

    & > .t9n-indicator {
      @include margin-left(7px);
      color: $lightTextColor;
    }

    & + .instructions {
      margin-top: -3px;
    }

    // BC
    & > .instructions {
      width: 100%;
    }
  }

  & > .instructions {
    margin-bottom: 5px;
  }

  & > .input {
    position: relative;

    &:after {
      @include clearafter;
    }

    & + .instructions {
      margin: 5px 0 0;
    }
  }

  & > .notice,
  & > .warning {
    margin: 5px 0 0;
  }
}

.field > .instructions,
// BC
.field > .heading > .instructions,
.checkboxfield .instructions {
  color: $mediumTextColor;

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin: 1em 0;
    @include padding-left(2em);
  }

  ul li {
    list-style-type: disc;
  }

  li + li {
    margin-top: 0.25em;
  }
}

#expand-status-btn {
  margin-left: 5px;
  width: 30px;
  height: 17px;
  padding: 0;
  line-height: 16px;
  border-radius: $smallBorderRadius;
  color: $mediumDarkTextColor;

  &:before {
    margin: 0;
  }
}

/* toggles and nested fields */
.nested-fields {
  margin: -24px;
  padding: 24px 24px 0;

  &.hidden {
    display: block;
    height: 0;
  }

  & > .field:last-child {
    padding-bottom: 24px;
  }
}

/* checkbox */
input.checkbox {
  opacity: 0;
  position: absolute;
  width: $checkboxSize;
  height: $checkboxSize;
}

input.checkbox + label,
div.checkbox {
  display: inline-block;
  clear: none;
  position: relative;
  @include padding-left($checkboxSize + 5);
  line-height: 16px;
  height: 16px;
  cursor: pointer;

  .info {
    height: 16px;
  }
}

input.checkbox:disabled + label,
.disabled div.checkbox {
  cursor: default;
}

input.checkbox + label:empty,
div.checkbox:empty {
  @include padding-left($checkboxSize);
}

input.checkbox + label:empty:after,
div.checkbox:empty:after {
  content: '';
  font-size: 0;
}

/* fixes a RTL bug */
input.checkbox + label:before,
div.checkbox:before {
  display: block;
  position: absolute;
  @include left(0);
  top: 0;
  width: $checkboxSize !important;
  height: $checkboxSize;
  box-sizing: border-box;;
  content: '';
  font-size: 0;
  background-color: hsl(212, 50%, 99%);
  border: 1px solid transparentize($inputColor, 0.6);
  background-clip: padding-box;
  border-radius: $smallBorderRadius;
}

input.checkbox:disabled + label,
div.checkbox.disabled:before,
div.checkbox.disabled + label {
  opacity: 0.25;
}

input.checkbox:checked + label:before,
div.checkbox.checked:before,
.sel div.checkbox:before,
input.checkbox:indeterminate + label:before,
div.checkbox.indeterminate:before {
  @include icon;
  line-height: $checkboxSize;
  color: $grey900;
}

input.checkbox:checked:not(:indeterminate) + label:before,
div.checkbox.checked:not(.indeterminate):before,
.sel div.checkbox:not(.indeterminate):before {
  content: 'check';
  font-size: 15px;
}

input.checkbox:indeterminate + label:before,
div.checkbox.indeterminate:before {
  content: 'minus';
  font-size: 7px;
  text-align: center;
}

input.checkbox:focus + label:before,
:focus div.checkbox:before {
  outline: none;
  border-color: $inputColor;
}

fieldset {
  .checkboxfield {
    margin: 5px 0;
  }
}

.checkboxfield {
  .instructions,
  .notice,
  .warning {
    margin-top: 2px;
    @include padding-left($checkboxSize + 5);
  }
}

/* multiselect */
.multiselect > select {
  color: $textColor;
  font-size: 14px;
  appearance: none;

  &:focus {
    @include input-focused-styles;
  }

  option {
    padding: 1px 8px;
  }
}

.text:not(.selectize-text),
.selectize-text > .selectize-control > .selectize-input {
  padding: 6px 9px;
}

.text {
  background-color: $white;

  &.small {
    padding: 3px;
  }

  &.readable {
    padding: 16px 18px;
    font-size: 16px;
    line-height: 22px;

    & + .chars-left {
      margin-top: -23px;
    }
  }

  input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
  }
}

.input.errors > .text,
.input.errors > .border-box,
.input.errors > .passwordwrapper,
.input.errors > .autosuggest-container .text,
.text.error {
  border: 1px solid $errorColor !important;
}

.texticon {
  position: relative;
  cursor: text;
  min-width: 130px;

  &.icon {
    &:before {
      position: absolute;
      top: 9px;
      @include left(9px);
      color: $mediumTextColor;
    }

    .text {
      @include padding-left(26px);
    }
  }

  &.clearable .text {
    @include padding-right(22px);
  }

  .clear {
    position: absolute;
    top: 6px;
    @include right(9px);
    color: $lightTextColor;
    cursor: pointer;

    &:hover {
      color: $mediumTextColor;
    }

    &:before {
      @include icon;
      content: 'remove';
    }
  }
}

.texthint-container {
  position: relative;
  height: 0;
}

.texthint {
  position: absolute;
  top: -1px;
  width: 100%;
  color: $lightTextColor;
  cursor: text;
}

.passwordwrapper {
  position: relative;
  display: flex;
  align-items: center;

  .password {
    flex: 1;
    border: none;
    background: transparent;
    box-shadow: none;
  }

  .password-toggle {
    @include padding-right(7px);
    color: $linkColor;

    &:hover {
      text-decoration: underline;
    }
  }
}

.datetimewrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .datewrapper + .timewrapper {
    @include margin-left(5px);
  }
}

.clear-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  cursor: pointer;
  color: $darkHairlineColor;
  border: none;
  padding: 0;
  background: transparent;

  &:before {
    @include icon;
    content: 'remove';
  }

  &:hover {
    color: $mediumTextColor;
  }
}

.datewrapper,
.timewrapper {
  display: inline-block;
  position: relative;

  .text {
    position: relative;
    z-index: 1;
    width: 100%;

    &:placeholder-shown + div[data-icon] {
      display: block;
      position: absolute;
      top: calc(50% - 10px);
      @include left(14px);
      z-index: 0;
      color: $lightTextColor;

      &,
      &:before {
        user-select: none;
        pointer-events: none;
        z-index: 1;
      }

      #details & {
        color: $darkHairlineColor;
      }
    }

    &:not(:placeholder-shown) + div[data-icon] {
      display: none;
    }
  }
}

.datewrapper {
  width: 8em;

  .text:before {
    top: calc(50% - 7px);
    @include left(7px);
    content: 'date';
  }
}

.timewrapper {
  width: 7em;

  .text:before {
    top: calc(50% - 5px);
    @include left(7px);
    font-size: 11px;
    content: 'time';
  }
}

@include placeholder-styles($grey300);

/* Kill IE's special text features */
::-ms-reveal,
::-ms-clear {
  display: none;
}

/* Assets related */
.hud.assetshud .body {
  max-height: 500px;
  overflow: auto;
}

// Selects
.select:not(.selectize),
.select:not(.selectize) select {
  @include select-styles;
}

.select:not(.selectize) {
  @include select-container-styles;
}

.select:not(.selectize):after {
  @include select-arrow-styles;
}

.select:not(.selectize) select {
  @include select-input-styles;
  white-space: pre;

  option {
    background-color: $white;
  }
}

.select:not(.selectize).fullwidth select {
  @include select-input-fullwidth-styles;
}

.select:not(.selectize) select {
  &:hover,
  &:focus {
    @include select-input-focused-styles;
  }
}

.select:not(.selectize).small:after {
  top: 9px;
}

.select:not(.selectize).small select {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 11px;
}

/* selectize reset */
.selectize .selectize-control.single .selectize-input {
  border-color: inherit;
  box-shadow: none;
  background-color: transparent;
}

.selectize .selectize-control.single .selectize-input:after {
  display: none;
}

body .selectize-dropdown {
  border: none;
}

/* single select styles */
.selectize.select .selectize-control,
.selectize.select .selectize-control .selectize-input {
  @include select-styles;
}

.selectize.select .selectize-control {
  @include select-container-styles;
}

.selectize.select .selectize-control:after {
  @include select-arrow-styles;
}

.selectize.select .selectize-control .selectize-input {
  @include select-input-styles;
}

.selectize.select.fullwidth .selectize-control,
.selectize.select.fullwidth .selectize-control .selectize-input {
  @include select-input-fullwidth-styles;
}

/* multi select styles */
.selectize.multiselect .selectize-control.multi .selectize-input {
  padding: 6px 8px;
}

.selectize.multiselect .selectize-control.multi .selectize-input.has-items {
  padding: 5px 8px;
}

.selectize.multiselect .selectize-control.multi .selectize-input > div {
  @include token-styles;
  margin-top: 1px;
  margin-bottom: 1px;
}

.selectize.multiselect .selectize-control.plugin-remove_button [data-value] .remove {
  padding: 0;
}

/* shared styles */
.selectize .selectize-control .selectize-input.focus {
  @include input-focused-styles;
}

/* menu styles */
body .selectize-dropdown {
  @include menu-styles;
  margin-top: 1px;
  padding: 0;
}

body .selectize-dropdown-content {
  padding: 3px 14px;
}

body .selectize-dropdown-content > div[data-value="new"]:before {
  @include icon;
  content: 'plus';
  margin-right: 5px;
}

body .selectize-dropdown-content > div[data-value="new"]:after {
  content: '…';
}

body .selectize-dropdown [data-selectable],
body .selectize-dropdown .optgroup-header {
  @include menu-option-styles;
}

body .selectize-dropdown .optgroup-header {
  @include h6-styles;
  margin: 0;
  padding: 4px 0;
}

body .selectize-dropdown .active {
  @include menu-option-active-styles;
}

/* datepicker */
.ui-datepicker {
  position: fixed;
  top: -300px;
  @include margin-left(1px);
  -padding: 10px;
  width: 210px;
  height: 242px;
  z-index: 101 !important;
}

.ui-datepicker-header {
  padding: 8px 8px 4px;
}

.ui-datepicker-prev {
  @include floatleft;
}

.ui-datepicker-next {
  @include floatright;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:after {
    border-color: $linkColor;
  }
}

.ui-datepicker-prev:after {
  @include angle(left);
}

.ui-datepicker-next:after {
  @include angle(right);
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
  padding: 2px !important;
}

.ui-datepicker-calendar th span,
.ui-datepicker-calendar td a {
  display: block;
  width: 26px;
  line-height: 26px;
  text-align: center;
  color: $textColor;
}

.ui-datepicker-calendar th span {
  color: $mediumTextColor;
  font-weight: normal;
}

.ui-datepicker-calendar td a {
  border-radius: 2px;
}

.ui-datepicker-calendar td a:hover {
  background-color: $lightSelColor;
  text-decoration: none;
}

.ui-datepicker-calendar td a.ui-state-active {
  background-color: $darkSelColor;
  color: $white;
  @include light-on-dark-text;
  cursor: default;
}

.ui-datepicker-calendar td.ui-datepicker-today a {
  border-radius: 13px;
  box-shadow: inset 0 0 0 2px $lightSelColor;
}

/* timepicker */
.ui-timepicker-wrapper {
  z-index: 101;
}

.ui-timepicker-list {
  @include margin-left(1px);
  overflow-y: auto;
  width: calc(14px + 6em);
  height: 210px;
  z-index: 100;
}

.ui-timepicker-list li {
  @include padding(2px, 0, 2px, 14px);
  white-space: nowrap;
  cursor: pointer;
}

.ui-timepicker-list li:hover {
  background-color: $lightSelColor;
}

.ui-timepicker-list li.ui-timepicker-selected {
  background-color: $darkSelColor;
  color: $white;
  @include light-on-dark-text;
  cursor: default;
}

/* slide picker */
.slide-picker {
  display: flex;
  height: 15px;
  white-space: nowrap;

  a {
    border: 1px solid $hairlineColor;
    @include border-left(none);
    background-image: none;
    width: 7px;
    height: 13px;
    margin-left: 0;
    display: inline-block;
    margin-top: 1px;
    margin-bottom: 1px;
    box-sizing: border-box;

    &:first-child {
      width: 8px;
      @include border-left(1px solid $grey400 !important);
    }
  }

  &:not(:hover) a.active,
  &:hover a.active-hover {
    border-top-color: $grey400;
    border-bottom-color: $grey400;
    height: 15px;
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      @include border-left(1px solid $grey400);
      @include border-top-left-radius($smallBorderRadius);
      @include border-bottom-left-radius($smallBorderRadius);
    }
  }

  &:not(:hover) a.last-active,
  &:hover a.last-active-hover {
    @include border-right(1px solid $grey400);
    @include border-top-right-radius($smallBorderRadius);
    @include border-bottom-right-radius($smallBorderRadius);
  }

  &:focus {
    outline: none;

    a.active {
      background-color: $lightSelColor;
    }
  }
}

/* errors */
ul.errors {
  margin-top: 5px;
  list-style-type: square;
  @include padding-left(20px);
}

ul.errors li {
  color: $errorColor;
}

/* message pages */
.message-container {
  position: absolute;
  z-index: 100;
  top: 0;
  @include left(0);
  width: 100%;
  height: 100%;

  &.no-access {
    background-color: transparentize($grey900, 0.5);
  }

  .pane {
    top: 50%;
    margin-top: -33px !important;
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    box-shadow: 0 25px 100px transparentize($grey900, 0.5);
  }
}

/* auto-suggest */
.autosuggest-container {
  position: relative;
}

.autosuggest__results-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: $largeBorderRadius;
  background-color: $white;
  @include shadow;
  box-sizing: border-box;
  padding: 0 14px;
  text-align: left;
  @include sans-serif-font;
  font-size: 1em !important;

  .autosuggest__results_title {
    @include h6-styles;
    margin-top: 14px !important;
  }

  .autosuggest__results_item {
    @include menu-option-styles;

    &:hover,
    &.autosuggest__results_item-highlighted {
      @include menu-option-active-styles;
      cursor: pointer;
    }
  }
}

/* ----------------------------------------
/* Matrix
/* ----------------------------------------*/

.matrix-configurator {
  & > .field {
    max-width: none;

    & > .input {
      position: relative;
      @include padding-left(440px);
      background-color: $grey050;
      overflow: hidden;
      box-shadow: none;

      &:after {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: visible;
        content: '';
        font-size: 0;
        border-radius: $smallBorderRadius;
        box-shadow: inset 0 1px 3px -1px darken($grey200, 10%);
        user-select: none;
        pointer-events: none;
      }
    }
  }

  .mc-sidebar {
    position: absolute;
    top: 0;
    margin: 0;
    border: none;
    height: 100%;
    box-sizing: border-box;

    .mc-col-items {
      margin-top: -1px;
      padding-top: 1px;
    }

    &.block-types {
      @include left(0);
      width: 200px;

      .mc-col-items {
        .btn {
          margin: 14px;
        }
      }
    }

    &.mc-fields {
      @include left(200px);
      width: 240px;
      z-index: 1;
      background: #fff;
      box-shadow: 0 0 0 1px transparentize($grey900, 0.9), 0 2px 5px -2px transparentize($grey900, 0.8);

      .mc-col-items {
        padding: 14px;

        .btn {
          margin-top: 14px;
        }
      }
    }
  }

  .mc-sidebar,
  .mc-field-settings {
    & > .mc-col-inner-container > .mc-col-heading {
      margin: 0;
      padding: 7px 14px 6px;
      border-bottom: 1px solid $hairlineColor;
      background-color: $grey050;
      background-image: linear-gradient(transparentize($grey800, 1), transparentize($grey800, 0.95));
    }
  }

  .mc-field-settings {
    flex: 1;
    position: relative;
    height: 100%;

    & > .mc-col-inner-container {
      & > .mc-col-heading {
        padding-left: 24px;
        padding-right: 24px;
      }

      & > .mc-col-items {
        padding: 24px;
      }
    }
  }
}

.matrixconfigitem {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
  min-height: 48px;
  box-sizing: border-box;

  &.mci-blocktype {
    margin-top: -1px;
    padding: 8px 14px;
    border: solid $hairlineColor;
    border-width: 1px 0;
    background-color: $grey100;

    &.sel {
      z-index: 1;
      background-color: $grey200;
    }
  }

  &.mci-field {
    border-radius: $mediumBorderRadius;
    padding: 7px 10px;
    background-color: $grey100;

    &.sel {
      background-color: $grey200;

      .slide-picker:focus a.active {
        background-color: darken($lightTextColor, 0.5%);
      }
    }

    & + .mci-field {
      margin-top: 7px;
    }
  }

  .mci-name {
    flex: 1;
    overflow: hidden;

    h4,
    .smalltext {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h4 {
      margin-bottom: 2px;
      font-weight: normal;
      color: $textColor;

      &.mci-required:after {
        @include icon;
        @include margin(-2px, 0, 0, 4px);
        content: 'asterisk';
        font-size: 8px;
      }
    }
  }

  &.error .mci-name h4 {
    color: $errorColor;
  }

  .slide-picker,
  .icon {
    @include margin-left(7px);
  }

  .icon {
    display: block;

    &:not(.error):not(:hover):before {
      color: $darkHairlineColor;
    }

    &.error:before {
      color: $errorColor;
    }
  }
}

/* Matrix fields */
.matrix {
  & > .buttons {
    margin-top: 10px;
  }
}

$titlebarBorderRadius: $largeBorderRadius - 1;

.matrixblock {
  position: relative;
  margin-bottom: 10px;
  padding: 0 14px 14px;
  border-radius: $largeBorderRadius;
  border: 1px solid $hairlineColor;
  background-color: $grey050;
  outline: none;

  &.static {
    padding-top: 14px;
  }

  & > .titlebar {
    margin: 0 -14px;
    width: calc(100% + 28px);
    box-sizing: border-box;
    border-radius: $titlebarBorderRadius $titlebarBorderRadius 0 0;
    @include padding(5px, 70px, 5px, 35px);
    color: $lightTextColor;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    cursor: default;
    user-select: none;
    position: relative;
    background-color: darken($grey050, 2%);

    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: -1px;
      left: 14px;
      width: calc(100% - 28px);
      height: 1px;
      background-color: $hairlineColor;
    }

    & > .blocktype {
      display: inline;
      color: $mediumTextColor;
    }

    & > .preview {
      @include margin-left(7px);
      display: inline;
      opacity: 0;
      transition: opacity linear 100ms;

      span {
        opacity: 0.5;
      }
    }
  }

  &.disabled {
    opacity: 1;

    & > .titlebar {
      @include padding-right(90px);
    }

    & > .actions {
      & > .status {
        &.off {
          display: block;
        }
      }
    }
  }

  &.collapsed {
    & > .titlebar {
      border-radius: $titlebarBorderRadius;
      border-bottom: none;

      & > .preview {
        opacity: 1;
      }
    }
  }

  & > .checkbox {
    position: absolute;
    top: 7px;
    @include left(14px);
  }

  & > .actions {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 4px;
    @include right(14px);
    cursor: default;

    & > * {
      @include margin(0, 0, 0, 10px);
    }

    & > .status {
      &.off {
        display: none;
      }
    }

    a {
      padding: 0 !important;
      height: 20px;
      text-align: center;
      color: $darkHairlineColor;
      transform: color linear 100ms;

      &.settings:after {
        @include margin-left(3px);
        border-color: $darkHairlineColor;
        transform: border-color linear 100ms;
      }

      &:hover {
        color: $linkColor;

        &.settings:after {
          border-color: $linkColor;
        }
      }
    }
  }

  &:not(.static) {
    & > .fields {
      padding-top: 14px;
    }
  }

  & > .fields {
    & > .field {
      margin: 15px 0;
    }
  }

  & > .buttons {
    margin-top: 0;
    height: 30px;
  }
}

/* categories */
.add-category-form {
  margin-top: 24px;
}

.add-category-form .texticon {
  width: 200px;
  @include floatleft;
  @include margin-right(5px);
}

.add-category-form .texticon .text {
  @include padding-right(30px);
}

.add-category-form .texticon .spinner {
  position: absolute;
  top: 0;
  @include right(5px);
}

.categoriesfield {
  position: relative;
  min-height: 30px;
}

.categoriesfield .structure ul {
  @include margin-left(12px);
}

/* site pickers */
body.sitepicker {
  #main-content {
    padding: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #content-container {
    max-width: 400px;
  }
}

.sitepicker-group {
  li {
    &:not(:first-child) {
      a {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:not(:hover) {
          border-top-color: transparent;
        }
      }
    }

    &:not(:last-child) {
      a {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    a {
      display: block;
      position: relative;
      text-align: left;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid $hairlineColor;
      border-radius: $mediumBorderRadius;
      @include padding(9px, 42px, 9px, 15px);
      font-size: 16px;
      line-height: 1.4;

      &:after {
        font-size: 14px;
        position: absolute;
        top: calc(50% - 7px);
        @include right(12px);
        margin: 0;
        padding: 0;
      }

      &:hover {
        border-color: $linkColor;
        text-decoration: none;
        z-index: 1;
      }
    }
  }
}

/* ----------------------------------------
/* IE hacks
/* ----------------------------------------*/

/* Fix layout of modal element selectors for IE8 */
.elementselectormodal .body .main {
  float: left \9
;
  width: 445px \9
;
}

/* ----------------------------------------
/*  Retina graphics
/* ----------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 1.5dppx) {
  table.data {
    thead {
      th.ordered.loading {
        background-image: url(../images/spinner_tableheader_2x.gif);
        background-size: 26px;
      }
    }
  }

  .spinner,
  .elements.busy:after {
    background-image: url(../images/spinner_2x.gif);
    background-size: 20px;
  }
  .spinner {
    &.over-bg {
      background-image: url(../images/spinner_bg_2x.gif);
    }
    &.big {
      background-image: url(../images/spinner_big_2x.gif);
      background-size: 48px;
      &.over-bg {
        background-image: url(../images/spinner_big_bg_2x.gif);
      }
    }
  }

  .btn.icon.add.loading:after {
    background-image: url(../images/spinner_2x.gif);
    background-size: 20px;
  }
  .btn.icon.add.loading.submit:after {
    background-image: url(../images/spinner_submit_2x.gif);
  }
  .secondary-buttons .btn.icon.add.loading.submit:after,
  .btn.secondary.icon.add.loading.submit:after {
    background-image: url(../images/spinner_submit_secondary_2x.gif);
  }

  .sel .element.loading:after,
  .element.loading.sel:after {
    background-image: url(../images/spinner_element_2x.gif);
    background-size: 20px;
  }

  .preview-thumb-container.loading:after {
    background-image: url(../images/spinner_dark_2x.gif);
    background-size: 20px;
  }

  .structure ul li {
    background-size: 40px;
    body.ltr & {
      background-image: url(../images/branch_2x.png);
    }
    body.rtl & {
      background-image: url(../images/branch_rtl_2x.png);
    }
  }

  .modal .resizehandle {
    background-size: 13px;
    body.ltr & {
      background-image: url(../images/resizehandle_2x.png);
    }
    body.rtl & {
      background-image: url(../images/resizehandle_rtl_2x.png);
    }
  }

  .hud .tip-left {
    background-image: url(../images/hudtip_left_2x.png);
    background-size: 15px 30px;
  }
  .hud .tip-top {
    background-image: url(../images/hudtip_top_2x.png);
    background-size: 30px 15px;
  }
  .hud .tip-right {
    background-image: url(../images/hudtip_right_2x.png);
    background-size: 15px 30px;
  }
  .hud .tip-bottom {
    background-image: url(../images/hudtip_bottom_2x.png);
    background-size: 30px 15px;
  }
  .hud.has-footer .tip-bottom {
    background-image: url(../images/hudtip_bottom_gray_2x.png);
  }
}
